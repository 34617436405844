import { message } from 'ant-design-vue';

message.config({maxCount: 1});

export const validatePassword = async (password) => {
  /* eslint-disable */
  const regex = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,12}");
  /* eslint-disable */
  if (password.length > 12 || !regex.test(password)) {
    return Promise.reject(
      "Geçerli bir şifre giriniz"
    );
  } else {
    return Promise.resolve();
  }
};

export const validateEmail = async (email) => {
  const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  if (!regex.test(email)) {
    return Promise.reject(
      'Geçerli bir mail adresi giriniz'
    );
  } else {
    return Promise.resolve();
  }
};

export const validatePhone = async (phone) => {
  const regex = new RegExp(/^(\+90|0)?5\d{9}$/)
  if (!regex.test(phone)) {
    return Promise.reject(
      'Geçerli bir numara giriniz'
    );
  } else {
    return Promise.resolve();
  }
};

export const errorMessage = (showMessage) => {
  message.error({
    content: () => showMessage,
    style: {
      marginTop: '20vh',
    },
  });
}
export const successMessage = (showMessage) => {
  message.success({
    content: () => showMessage,
    style: {
      marginTop: '20vh',
    },
  });
}

export const getDateFormatted = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
