<template>
    <a-spin :spinning="$store.getters['isLoading']" size="large" class="space" tip="Lütfen bekleyin...">
        <a-space direction="vertical" class="space">
            <a-layout>
                <a-layout-header class="header">
                    <HeaderComponent />
                </a-layout-header>
                <a-layout-content class="content pr-5 pl-5 pt-10" style="margin-top: 64px;">
                    <router-view />
                </a-layout-content>
            </a-layout>
        </a-space>
    </a-spin>
</template>
<script setup>
import HeaderComponent from './HeaderComponent.vue';
</script>
<style scoped>
.content {
    background-color: white;
}

.space {
    width: 100%;
    height: 100%; 
}

.header {
    padding: 0px 10px;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
}
</style>