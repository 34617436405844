<template>
    <InviteModal :displayInviteModal="displayInviteModal" :companyUserRelList="auth.user?.companyUserRelList || []"
        @closeInviteModal="closeInviteModal" />
    <div id="header">
        <a-row>
            <a-col style="padding: 0px;">
                <a-menu v-if="auth.status.loggedIn" deselect="false" mode="horizontal" :items="leftMenuItems"
                    @click="handleLeftMenuClick" style="background-color: transparent; height: 64px;border: 0px; margin-left: -30px;" />
                <img v-else src="@/assets/logo/logo_light_with_text.png" alt="" style="width: 200px;">
            </a-col>
            <a-col flex="auto" class="icon mt-10" v-show="store.state.auth.status.loggedIn">
                <a-badge :count="basketCount" class="badge-space">
                    <ShoppingCartOutlined v-if="basketCount" @click="$router.push('/basket')" :style="iconStyle" />
                </a-badge>
                <a-badge :count="notificationList?.length" class="badge-space">
                    <a-popover placement="bottom">
                        <BellOutlined :style="{ color: `${bellColor}`, fontSize: '28px' }" />
                        <template #content>
                            <div v-if="notificationList.length">
                                <ul v-for="item in notificationList" :key="item.id">
                                    <div @click="readNotification(item)" class="notif-table p-10">
                                        <p>{{ `${item.title}` }} </p>
                                        <p>{{ `${item.description}` }} </p>
                                    </div>
                                </ul>
                            </div>
                            <div v-else>
                                <p>Okunmamış bildirim yok</p>
                            </div>

                        </template>
                    </a-popover>
                </a-badge>
                <a-dropdown>
                    <template #overlay>
                        <a-menu @click="handleRightMenuClick">
                            <a-menu-item key="1" title="account" role="account">
                                Hesap Ayarları
                            </a-menu-item>
                            <a-menu-item key="2" title="worker" role="worker">
                                Çalışan Ayarları
                            </a-menu-item>
                            <a-menu-item v-if="auth.user.companyUserRelList[0].isAdmin" key="3" title="invite" role="invite">
                                Çalışan Davet Et
                            </a-menu-item>
                            <a-menu-item key="4" role="logout" style="color: red;">
                                Çıkış
                            </a-menu-item>
                        </a-menu>
                    </template>
                    <a-badge :count="packageAlert.length" class="badge-space">
                        <UserOutlined :style="iconStyle" />
                    </a-badge>
                </a-dropdown>
            </a-col>
        </a-row>
    </div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed, onBeforeMount, ref, h } from 'vue'
import { useRouter } from 'vue-router';
import InviteModal from '../pages/InviteModal.vue';
import { BellOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons-vue'
import notificationService from '@/services/notification.service';

const store = useStore()
const router = useRouter()

const { auth } = store.state
const basketCount = computed(() => auth.basket?.basketItems?.length || 0)
const infos = computed(() => auth.mainPageInfo)
const notificationList = computed(() => infos.value?.notificationList.filter(item => !item.isRead) || [])
const packageAlert = computed(() => infos.value?.packageAlert || [])
const displayInviteModal = ref(false);

const openInviteModal = () => {
    displayInviteModal.value = true;
}
const closeInviteModal = () => {
    displayInviteModal.value = false;
}

const iconStyle = {
    color: 'white',
    fontSize: '28px',
    paddingRight: '5px'
}

const leftMenuItems = ref([
    {
        key: 'leftMenu',
        label: '',
        title: '',
        icon: () => h('img', { style: { width: '200px' }, src: require('@/assets/logo/logo_light_with_text.png') }),
        children: [
            {
                type: 'group',
                children: [
                    {
                        label: 'Ana Sayfa',
                        key: 'homePage',
                    },
                    {
                        label: 'Yaklaşan İşler',
                        key: 'upcomingTasks',
                    },
                    {
                        label: 'Yeni Müşteri',
                        key: 'newClient',
                    },
                    {
                        label: 'Müşteri Arama',
                        key: 'searchClient',
                    },
                    {
                        label: 'Davet Edilenler',
                        key: 'inviteList',
                    },
                    // {
                    //     label: 'Şirketim',
                    //     key: 'company',
                    // },
                ],
            }
        ],
    }
]);

onBeforeMount(() => {
    if (store.state.auth.status.loggedIn) {
        store.dispatch('auth/mainPageInfo')
        store.dispatch('auth/basketCount')
    }
})

const handleRightMenuClick = (e) => {
    switch (e.item.role) {
        case 'logout':
            handleLogout();
            break;
        case 'worker':
            router.push('/worker-page');
            break;
        case 'account':
            router.push('/user-detail');
            break;
        case 'invite':
            openInviteModal();
            break;
        default:
            console.log('Unknown role');
    }
}
const handleLeftMenuClick = (e) => {
    switch (e.key) {
        case 'homePage':
            router.push('/');
            break;
        case 'upcomingTasks':
            router.push('/upcoming-tasks');
            break;
        case 'newClient':
            router.push('/new-client');
            break;
        case 'searchClient':
            router.push('/client-search');
            break;
        case 'company':
            router.push('/company');
            break;
        case 'inviteList':
            router.push('/invite-list');
            break;
        default:
            console.log('Unknown role');
    }
}

const handleLogout = () => {
    store.dispatch('auth/logout');
    router.push('/login');
}
const readNotification = (item) => {
    notificationService.readNotification(item.id).then(() => {
        store.dispatch('auth/mainPageInfo')
    })
}



const bellColor = computed(() => {
    if (notificationList.value == 0) {
        return 'white'
    }
    return '#ff4d4f'
})

</script>
<style>
.icon {
    text-align: right;
    margin-right: -15px
}

.badge-space {
    margin-right: 15px;
    padding-bottom: 18px;
}

.notif-table {
    border-bottom: 1px solid #f0f0f0;
}

#header .ant-menu-submenu-title {
    height: 64px;
}

#header .ant-menu-submenu-active::after,
#header .ant-menu-submenu-selected::after {
    border-bottom-color: transparent !important;
}

#header span,
li {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
</style>