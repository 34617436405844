<template>
    <a-modal :open="displayInviteModal" title="Davet Et" footer centered destroyOnClose closable
        @cancel="emit('close-invite-modal')" class="invite">
        <a-form ref="formRef" :model="formState" name="login" autocomplete="off" :rules="rules"
            :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" @finish="onFinish" @finishFailed="onFinishFailed">
            <a-form-item label="Eklenecek şirket" name="companyId">
                <a-radio-group v-model:value="formState.companyId" button-style="solid" class="mt-5">
                    <a-radio-button :value="item.company.id" v-for="item in companyUserRelList"
                        :key="item.company.id">{{ item.company.name }}</a-radio-button>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="Mail Adresi" name="email" has-feedback>
                <a-input @keydown.space="(event) => event.preventDefault()" v-model:value="formState.email" />
            </a-form-item>
            <a-button type="primary" class="button" block html-type="submit">Davet Gönder
            </a-button>
        </a-form>
    </a-modal>
</template>

<script setup>
import { defineProps, defineEmits, toRefs, ref } from 'vue'
import inviteService from '@/services/invite.service';
import { successMessage, errorMessage } from '@/util';

const props = defineProps(['displayInviteModal', 'companyUserRelList'])
const { displayInviteModal } = toRefs(props)
const emit = defineEmits(['close-invite-modal'])

const formState = ref({
    email: '',
    companyId: props.companyUserRelList[0]?.company.id,
});
const rules = {
    email: [
        { required: true, message: 'Lütfen mail adresinizi giriniz' },
        { type: 'email', message: 'Lütfen geçerli bir mail adresi giriniz' }
    ],
    companyId: [
        { required: true, message: 'Lütfen şirket seçiniz' }
    ]
};
const onFinish = () => {
    const data ={
        email: formState.value.email.toLowerCase().trim(),
        companyId: formState.value.companyId
    }
    inviteService.inviteByEmail(data).then(() => {
        successMessage('Davet başarıyla gönderildi');
        emit('close-invite-modal')
    }).catch((err) => {
        errorMessage(err.response.data.message)
    })
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed w:', errorInfo);
};

</script>

<style scoped>
.invite {
    width: 80%;
    max-width: 500px;
}

.button {
    background-color: #194570;
    margin-bottom: 20px;
}
</style>