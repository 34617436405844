import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex';
import homePage from './home.page';
import infoPages from './info.pages';
import authPage from './auth.page';

const routes = [
  ...homePage,
  ...authPage,
  ...infoPages,
  {
    path: '/plans',
    name: 'plans',
    component: () => import('../components/pages/PlanPurchasePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customer-service-info',
    name: 'customerServiceInfo',
    component: () => import('../components/pages/PublicClientServiceLogs.vue'),
  },
  {
    path: '/activate-user',
    name: 'activateUser',
    component: () => import('../components/pages/ActivateUser.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const store = useStore();
  let loggedIn = store.state.auth.status.loggedIn
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next('/login');
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (loggedIn) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }

});

export default router
