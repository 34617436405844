<template>
  <a-space direction="vertical" class="cardItem">
    <PlanCard v-if="!store.state.auth.mainPageInfo?.hasActivePlan" @click="$router.push({ name: 'plans' })" />
    <UpcomingCard :display-modal="upcomingModal" @open="openUpcomingModal" @click="$router.push({ name: 'upcomingTasks' })" />
    <NewClientCard @click="$router.push('/new-client')" />
    <ClientCard @click="$router.push('/client-search')" />
    <!-- <CompanyCard @click="$router.push('/company')" /> -->
  </a-space>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import PlanCard from '@/components/pages/homePageCards/PlanCard.vue';
import UpcomingCard from '@/components/pages/homePageCards/UpcomingCard.vue';
//import CompanyCard from '@/components/pages/homePageCards/CompanyCard.vue';
import ClientCard from '@/components/pages/homePageCards/ClientCard.vue';
import NewClientCard from '@/components/pages/homePageCards/NewClientCard.vue';
import { notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

onMounted(() => {
  if (!store.state.auth.mainPageInfo?.hasActivePlan && store.state.auth.basketCount == 0) {
    openNotification()
  }
});

const key = `open${Date.now()}`;
const openNotification = () => {
  notification.open({
    message: ``,
    description:
      `Paket satın almak için lütfen tıklayınız.`,
    placement: 'bottom',
    onClick: () => {
      router.push('/plans');
      notification.close(key);
    },
    key,
    duration: 3,
    maxCount: 1,
  });
};

const upcomingModal = ref(false)
const openUpcomingModal = () => {
  upcomingModal.value = !upcomingModal.value
}

</script>

<style scoped>
.cardItem {
  margin-top: 10px;
}
</style>