<template>
    <InviteModal :displayInviteModal="displayInviteModal"
        :companyUserRelList="store.state.auth.user?.companyUserRelList || []" @closeInviteModal="closeInviteModal" />
    <div id="header">
        <a-row>
            <a-col style="padding: 0px;">
                <img src="@/assets/logo/logo_light_with_text.png" alt="" style="width: 200px;">
            </a-col>
            <a-col flex="auto" class="icon mt-10" v-show="store.state.auth.status.loggedIn">
                <a-dropdown v-if="isAdmin">
                    <template #overlay>
                        <a-menu @click="handleRightMenuClick">
                            <div v-for="(item, index) in adminMenu" :key="index">
                                <a-menu-item class="menu-item" :role="item.role"
                                    :style="selectedMenuItem === item.role ? selectedMenuStyle : ''">
                                    {{ item.title }}
                                </a-menu-item>
                            </div>
                        </a-menu>
                    </template>
                    <CrownOutlined :style="iconStyle"/>
                </a-dropdown>
                <a-badge :count="basketCount" class="badge-space">
                    <ShoppingCartOutlined v-if="basketCount" @click="$router.push('/basket')" :style="iconStyle" />
                </a-badge>
                <a-badge :count="notificationList?.length" class="badge-space">
                    <a-popover placement="bottom">
                        <BellOutlined :style="{ color: `${bellColor}`, fontSize: '28px' }" />
                        <template #content>
                            <div v-if="notificationList.length">
                                <ul v-for="item in notificationList" :key="item.id">
                                    <div @click="readNotification(item)" class="notif-table p-10">
                                        <p>{{ `${item.title}` }} </p>
                                        <p>{{ `${item.description}` }} </p>
                                    </div>
                                </ul>
                            </div>
                            <div v-else>
                                <p>Okunmamış bildirim yok</p>
                            </div>

                        </template>
                    </a-popover>
                </a-badge>
                <a-dropdown>
                    <template #overlay>
                        <a-menu @click="handleRightMenuClick">
                            <div v-for="(item, index) in menuList" :key="index">
                                <a-menu-item class="menu-item" :danger="item.danger"
                                    v-if="item.planRequired ? store.state.auth.mainPageInfo?.hasActivePlan : true"
                                    :role="item.role" :style="selectedMenuItem === item.role ? selectedMenuStyle : ''">
                                    {{ item.title }}
                                </a-menu-item>
                            </div>
                        </a-menu>
                    </template>
                    <a-badge :count="packageAlert.length" class="badge-space">
                        <MenuOutlined :style="iconStyle" />
                    </a-badge>
                </a-dropdown>
            </a-col>
        </a-row>
    </div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router';
import InviteModal from '../pages/InviteModal.vue';
import { BellOutlined, ShoppingCartOutlined, MenuOutlined, CrownOutlined } from '@ant-design/icons-vue'
import notificationService from '@/services/notification.service';

const store = useStore()
const router = useRouter()

const isAdmin = computed(() => store.getters['auth/isAdmin'])
const basketCount = computed(() => store.state.auth.basket?.basketItems?.length || 0)
const infos = computed(() => store.state.auth.mainPageInfo)
const notificationList = computed(() => infos.value?.notificationList.filter(item => !item.isRead) || [])
const packageAlert = computed(() => infos.value?.packageAlert || [])
const displayInviteModal = ref(false);

const menuList = [
    { title: 'Ana Sayfa', role: 'homePage', planRequired: false },
    { title: 'Hesap Ayarları', role: 'account', planRequired: false },
    { title: 'Çalışan Ayarları', role: 'worker', planRequired: true },
    { title: 'Yaklaşan İşler', role: 'upcomingTasks', planRequired: true },
    { title: 'Müşteri Ekle', role: 'newClient', planRequired: true },
    { title: 'Müşteri Arama', role: 'searchClient', planRequired: true },
    // { title: 'Şirketim', role: 'company', planRequired: true },
    { title: 'Davet Listesi', role: 'inviteList', planRequired: true },
    { title: 'Davet Gönder', role: 'invite', planRequired: true },
    { title: 'Çıkış', role: 'logout', planRequired: false, danger: true }
]

const adminMenu = [
    { title: 'Planlar', role: 'adminPlan', isAdmin: true },
]

const selectedMenuStyle = {
    backgroundColor: '#1677ff',
    color: 'white'
}

const selectedMenuItem = ref('homePage')

const openInviteModal = () => {
    displayInviteModal.value = true;
}
const closeInviteModal = () => {
    displayInviteModal.value = false;
}

const iconStyle = {
    color: 'white',
    fontSize: '28px',
    paddingRight: '5px'
}

onBeforeMount(() => {
    if (store.state.auth.status.loggedIn) {
        store.dispatch('auth/mainPageInfo')
        store.dispatch('auth/basketCount')
    }
})

const handleRightMenuClick = (e) => {
    selectedMenuItem.value = e.item.role
    switch (e.item.role) {
        case 'logout':
            handleLogout();
            selectedMenuItem.value = 'homePage'
            break;
        case 'worker':
            router.push('/worker-page');
            break;
        case 'account':
            router.push('/user-detail');
            break;
        case 'invite':
            openInviteModal();
            break;
        case 'homePage':
            router.push('/');
            break;
        case 'upcomingTasks':
            router.push('/upcoming-tasks');
            break;
        case 'newClient':
            router.push('/new-client');
            break;
        case 'searchClient':
            router.push('/client-search');
            break;
        case 'company':
            router.push('/company');
            break;
        case 'inviteList':
            router.push('/invite-list');
            break;
        case 'adminPlan':
            router.push('/admin/all-plan');
            break;
        default:
            console.log('Unknown role');
    }
}

const handleLogout = () => {
    store.dispatch('auth/logout');
    router.push('/login');
    window.location.reload()
}

const readNotification = (item) => {
    notificationService.readNotification(item.id).then(() => {
        store.dispatch('auth/mainPageInfo')
    })
}

const bellColor = computed(() => {
    if (notificationList.value == 0) {
        return 'white'
    }
    return '#ff4d4f'
})

</script>
<style>
.icon {
    text-align: right;
    margin-right: -15px
}

.badge-space {
    margin-right: 15px;
    padding-bottom: 18px;
}

.notif-table {
    border-bottom: 1px solid #f0f0f0;
}

.menu-item {
    width: 200px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 10px;
    margin-top: 2px;
}
</style>